/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useBookingStore} from './core/bookingsStore'
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom'
import {BookingHeader} from './SingleEventPartials/BookingHeader'
import {BookingTickets} from './SingleEventPartials/BookingTickets'
import {BookingTransactions} from './SingleEventPartials/BookingTransactions'
import {PageDataModel} from '../../../_components/SBPageTitle/PageDataStore'
import {Breadcrumbs} from '../../../_components/SBPageTitle/Breadcrumbs'
import {useAuthStore} from '../auth/core/authStore'

export function SingleBookingPage() {
  const {getBooking, currentBooking} = useBookingStore()
  const [crumbs, setCrumbs] = useState<PageDataModel[]>([])
  const {bookingId} = useParams<{bookingId: string}>()
  const {isLoggedIn} = useAuthStore()

  useEffect(() => {
    if (bookingId) {
      getBooking(bookingId)
    }
    // eslint-disable-next-line
  }, [])

  // Setup Breadcrumbs
  useEffect(() => {
    const crumbs: PageDataModel[] = [
      {
        object: 'Events',
        title: 'Events',
        link: '/events',
      },
      {
        object: `Bookings`,
        title: `Bookings`,
        link: `/events/${currentBooking.event?.id}/bookings`,
      },
    ]
    setCrumbs(crumbs)
  }, [currentBooking])

  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='tickets'
          element={
            <>
              {isLoggedIn() && (
                <>
                  <Breadcrumbs
                    className='mb-10'
                    breadcrumbs={[
                      ...crumbs,
                      {
                        object: 'Tickets',
                        title: 'Tickets',
                        link: `/bookings/${currentBooking.id}/tickets`,
                      },
                    ]}
                  />
                </>
              )}

              <BookingHeader />
              <BookingTickets />
            </>
          }
        />
        <Route
          path='transactions'
          element={
            <>
              {isLoggedIn() && (
                <>
                  <Breadcrumbs
                    className='mb-10'
                    breadcrumbs={[
                      ...crumbs,
                      {
                        object: 'Payments',
                        title: 'Payments',
                        link: `/bookings/${currentBooking.id}/transactions`,
                      },
                    ]}
                  />
                </>
              )}
              <BookingHeader />
              <BookingTransactions />
            </>
          }
        />

        <Route index element={<Navigate to={`/bookings/${bookingId}/tickets`} />} />
      </Route>
    </Routes>
  )
}
