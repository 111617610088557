import {FC} from 'react'
import SearchBar from './partial/SearchBar'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'

const HomePage: FC = () => {
  const ogData = {
    title: 'skybookings.com',
    description:
      "A platform for SA's most beautiful events.  Sell your tickets online and get your ticket sales paid out weekly or on request.",
    url: 'https://skybookings.com',
    image: 'https://skybookings.com/og-image.jpg',
    siteName: 'skybookings.com',
    type: 'website',
  }

  return (
    <>
      {/* SEO STUFF */}
      <Helmet>
        <title>skybookings.com</title>
        <meta name='skybookings.com' content='Boutique Ticketing Platform' />
        <meta property='og:title' content={ogData.title} />
        <meta property='og:description' content={ogData.description} />
        <meta property='og:url' content={ogData.url} />
        <meta property='og:image' content={ogData.image} />
        <meta property='og:site_name' content={ogData.siteName} />
        <meta property='og:type' content={ogData.type} />
      </Helmet>

      <div className='d-flex p-5 justify-content-end bg-black'>
        {/* login menu */}

        <Link to='/auth' className='text-gray-600 text-hover-primary me-3'>
          Login
        </Link>
        <Link to='/auth/registration' className='text-gray-600 text-hover-primary me-3'>
          Sign up
        </Link>
      </div>
      <div className='d-flex flex-grow-1 bg-black p-20 vh-100 justify-content-center align-items-center h-100'>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 mb-10'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default-dark.png')}
              className='h-30px'
            />
          </div>
          <div className='d-flex flex-column align-items-center'>
            <SearchBar />

            <div className='d-flex mt-10 '>
              <a
                href='https://skybookings.net/start2/'
                rel='noreferrer'
                target='_blank'
                className='me-2'
                style={{color: '#919191'}}
              >
                Boutique Ticketing Platform. Learn more.
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex p-5 bg-black'>
        {/* version  */}
        <div className='text-dark fw-bold text-hover-primary'>{process.env.REACT_APP_VERSION}</div>
      </div>
    </>
  )
}

export default HomePage
