import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate, useNavigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import EventEditor from '../modules/events/EventEditor'
import {EventsPageWrapper} from '../modules/events/EventsPage'

import {SingleBookingPage} from '../modules/bookings/SingleBookingPage'
import {BookingsPage} from '../modules/bookings/BookingsPage'
import {SingleEventPage} from '../modules/events/SingleEventPage'
import GuestListPage from '../modules/guests/GuestListPage'
import ScannersPage from '../modules/scanners/ScannersPage'
import {TransactionsPage} from '../modules/transactions/TransactionsPage'
import AdminSettlementsPage from '../pages/settlements/AdminSettlementsPage'
import AdminAccountsPage from '../pages/accounts/AdminAccountsPage'
import {EventStatementPage} from '../modules/events/EventStatementPage'
import {EventAnalyticsPage} from '../modules/events/EventAnalyticsPage'

const PrivateRoutes = () => {
  const SettingsPage = lazy(() => import('../modules/settings/SettingsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}

        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />

        {/* Event */}
        <Route path='events/*' element={<Navigate to='/' />} />
        <Route path='events/' element={<EventsPageWrapper />} />
        <Route path='events/create' element={<EventEditor />} />
        <Route path='events/:eventId' element={<SingleEventPage />} />
        <Route path='events/:eventId/edit' element={<EventEditor />} />
        <Route path='events/:eventId/bookings/*' element={<BookingsPage />} />
        <Route path='events/:eventId/guests/*' element={<GuestListPage />} />
        <Route path='events/:eventId/guests/:alphabet/*' element={<GuestListPage />} />
        <Route path='events/:eventId/statement/*' element={<EventStatementPage />} />
        <Route path='events/:eventId/scanners/*' element={<ScannersPage />} />
        <Route path='events/:eventId/analytics/*' element={<EventAnalyticsPage />} />

        {/* TRANSACTIONS */}
        <Route path='account/*' element={<Navigate to='/' />} />
        <Route path='account/' element={<TransactionsPage />} />

        {/* BOOKINGS */}
        <Route path='bookings/:bookingId/*' element={<SingleBookingPage />} />

        {/* SUPER USER */}
        <Route path='admin/settlements/*' element={<Navigate to='/' />} />
        <Route path='admin/settlements/' element={<AdminSettlementsPage />} />

        <Route path='admin/accounts/*' element={<Navigate to='/' />} />
        <Route path='admin/accounts/' element={<AdminAccountsPage />} />

        <Route path='admin/settings/*' element={<Navigate to='/' />} />
        <Route path='admin/settings/' element={<SettingsPage />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
